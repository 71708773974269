<template>
  <!-- <loading-component></loading-component> -->
  <modal-component-vue @on-close="showModal = false" v-if="showModal">
    <h1 class="text-base font-medium mt-0">Enter Phone Number</h1>
    <p class="text-[10px] my-2">
      To cancel your appointment, you will need to provide the phone number used
      in scheduling your appointment
    </p>
    <div class="flex gap-x-3 my-2">
      <select class="border rounded p-1 text-sm" id="">
        <option value="">+234</option>
        <option value="">+233</option>
      </select>
      <input type="text" class="p-1 flex-1 border rounded" />
    </div>
    <button
      @click="doBooking()"
      class="brand-button-default px-5 my-3 py-3 rounded text-[9px] uppercase"
    >
      Proceed
    </button>
  </modal-component-vue>
  <div
    v-if="!error"
    class="bg-white w-9/12 mt-12 mx-auto rounded-xl shadow-md pb-12"
  >
    <div
      :style="{ 'background-image': `url('${bannerUrl}')` }"
      class="bg-cover h-[100px] bg-gray-400 rounded-xl"
    ></div>
    <div
      class="md:w-10/12 bg-white mx-auto -mt-12 rounded-md border border-gray-200"
    >
      <div class="flex p-4 gap-x-3">
        <div class="w-4/6 text-left">
          <short-name-component-vue v-if="!logoUrl" :name="businessName"></short-name-component-vue>
          <img v-else class="w-10 h-10 rounded-full" :src="logoUrl" alt="">
          <h6 class="text-xs my-2">
            Lagos, Nigeria. <span>Open Until 8:00 am</span>
          </h6>
          <!-- <p class="m-0 text-gray-500 leading-snug text-xs">
            John Chris Limited is a lifestyle and wine business that meets your
            wine taste. It has more than 20 stores in 5 different cities and is
            100% focused on customer satisfaction with excellent services
          </p> -->
        </div>
      </div>
    </div>

    <div
      class="mt-4 text-left w-10/12 mx-auto rounded-xl border border-gray-200"
    >
      <dialog-message-component v-if="locations.length < 1 && loading === false">
        <h1 class="font-bold my-1">Notice!</h1>
        <p class="my-2 text-sm">No Stores</p>
      </dialog-message-component>

      <div v-else>
        <h1 class="text-lg font-bold m-4">
          Our Locations ({{ locations.length }})
        </h1>

        <div
          v-for="(item, index) in locations"
          :key="index"
          class="location p-4 border-t border-gray-200"
        >
          <div class="flex flex-col md:flex-row gap-x-3">
            <div class="md:w-2/3">
              <short-name-component-vue
                :name="item.noona_business.name"
              ></short-name-component-vue>
              <h6 class="text-xs my-1">
                {{ item.noona_business.location.formatted_address }}
                <!-- <span>Open Until 8:00 am</span> -->
              </h6>
              <p
                v-if="item.noona_business.profile.description"
                class="text-[10px]"
              >
                {{ item.noona_business.profile.description }}
              </p>
              <div class="my-2 flex gap-x-2">
                <button
                  @click="doBooking(item)"
                  class="brand-button-default px-2 py-1 rounded text-[9px] uppercase"
                >
                  Book Appointment
                </button>
                <!-- <button @click="showModal = !showModal"
                class="brand-button-inactive px-2 py-1 rounded text-[9px] uppercase"
              >
                Cancel Appointment
              </button> -->
              </div>
            </div>
            <div class="flex-1 mt-4 md:mt-0">
              <div class="grid grid-cols-2">
                <div class="col-span-1">
                  <a
                    :href="
                      'tel:+' +
                      item.noona_business.phone_country_code +
                      item.noona_business.phone_number
                    "
                    class="text-center"
                  >
                    <img
                      src="@/assets/phone.svg"
                      class="w-[30px] h-[30px] block mx-auto mb-1"
                    />
                    <div class="text-xs">Call</div>
                  </a>
                </div>
                <div class="col-span-1">
                  <a
                    :href="'mailto:' + item.business_email"
                    class="text-center"
                  >
                    <img
                      src="@/assets/mail.svg"
                      class="w-[30px] h-[30px] block mx-auto mb-1"
                    />
                    <div class="text-xs">Messages</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="h-screen flex items-center">
    <dialog-message-component class="w-2/3">
      <h1 class="font-bold my-1">Error Occured</h1>
      <p class="my-2 text-sm">{{ error }}</p>
    </dialog-message-component>
  </div>
</template>

<script>
import ModalComponentVue from '@/components/ModalComponent.vue'
import ShortNameComponentVue from '@/components/ShortNameComponent.vue'
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  created () {
    this.loading = true
    this.slugName = this.$route.params.slug
    const slug = this.$route.params.slug
    if (slug) {
      this.$trackEvent('TA_NS_PAGE_VISIT', {
        route: this.$route.name,
        slug: this.$route.params.slug
      })

      this.$store.dispatch('GET_DETAILS', slug).then((data) => {
        if (data.status) {
          this.loading = false
          console.log('Consumed from slug: ', data)
          const bannerUrl =
            ((data.data || [])[0] || { booking_preference: {} })
              .booking_preference.banner || null

          const logoUrl =
            ((data.data || [])[0] || { booking_preference: {} })
              .booking_preference.logo || null

          const businessName =
            ((data.data || [])[0] || { business_name: 'NA' })
              .business_name || 'NA'

          const bookingPreference = (
            (data.data || [])[0] || { booking_preference: {} }
          ).booking_preference

          this.bookingPreference = bookingPreference
          this.bannerUrl = bannerUrl
          this.logoUrl = logoUrl
          this.businessName = businessName
          this.locations = data.data
        } else {
          this.error = data.message
          this.loading = false
        }
      })
    } else {
      this.error = 'The website URL does not exist'
    }
    // console.log('slug: ', slug)
  },
  data () {
    return {
      showModal: false,
      bannerUrl: null,
      logoUrl: null,
      locations: [],
      slugName: null,
      error: false,
      bookingPreference: null,
      loading: false,
      businessName: ''
    }
  },
  methods: {
    doBooking (record) {
      console.log('doBooking: ', record._id)
      this.$trackEvent('TA_NS_GOTO_BOOKAPPOINTMENT')
      this.$store.commit('SET_NOONA_ID', record._id)
      this.$store.commit('SET_BUSINESS_ID', record.business_id)
      this.$store.commit('SET_BOOKING_PREFERENCE', this.bookingPreference)
      this.$store.commit('SET_BUSINESS_NAME', record.business_name)
      this.$store.commit('SET_SLUG', this.slugName)
      this.$router.push(`/${this.slugName}/book-appointment/${record._id}`)
    }
  },
  components: {
    ModalComponentVue,
    ShortNameComponentVue
  }
}
</script>
