<template>
    <div class="p-6 bg-white mx-auto rounded-md border border-red-200">
        <slot></slot>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="postcss" scoped>

</style>
