import { createStore } from 'vuex'
const API_URL = process.env.VUE_APP_API_URL
export default createStore({
  state: {
    noonaId: null,
    serviceIds: null,
    services: [],
    employee: {
      employee_id: null,
      employee_name: null
    },
    businessId: null,
    bookingDate: null,
    bookingPreference: {
      booking_amount: 0,
      booking_plan: null
    },
    customer: {
      name: null,
      email: null,
      country_code: null,
      phone: null
    },
    businessName: null,
    slug: null
  },
  getters: {
    totalDuration (state) {
      const durationList = state.services.map(item => item.category.services).flat()
      return durationList.reduce((acc, cur) => acc + cur.minutes, 0)
    },
    bookingPayload (state, getters) {
      return {
        business_id: state.businessId,
        employee_name: state.employee ? state.employee.employee_name || null : null,
        employee_id: state.employee ? state.employee.employee_id || null : null,
        booking_date: state.bookingDate,
        booking_fee: state.bookingPreference.booking_amount,
        total_amount: getters.fullTotal,
        payment_redirect_url: `${window.location.origin}/${state.slug}/payment`,
        customer: state.customer,
        service: getters.serviceListPayloadFormat
      }
    },
    serviceListPayloadFormat (state, getters) {
      return state.services.map(item => item.category.services.filter((service) => {
        return item.selectedServiceIds.includes(service.id)
      })).flat().map((item) => {
        return {
          description: item.description,
          id: item.id,
          duration: item.minutes,
          title: item.title,
          variations: item.variations
        }
      })
    },
    employeeName (state) {
      return (state.employee || { employee_name: null }).employee_name
    },
    employeeId (state) {
      return (state.employee || { employee_id: null }).employee_id
    },
    servicesList (state) {
      const list = state.services.map(item => item.category.services.filter((service) => {
        return item.selectedServiceIds.includes(service.id)
      })).flat()
      const extractPrices = list.map((item) => { return { title: item.title, price: item.variations } })
      const deeperExtraction = extractPrices.map((service) => { return { title: service.title, price: service.price.map(price => price.prices.reduce((acc, cur) => acc + cur.amount, 0)) } })
      return deeperExtraction.map((item) => { return { title: item.title, price: item.price.reduce((acc, cur) => acc + cur, 0) } })
      // const extracted = list.map(service => service.variations).flat()
      // return extracted.map((item) => { return { label: item.label, prices: item.prices.reduce((acc, cur) => acc + cur.amount, 0) } })
    },
    servicesCost (state, getters) {
      return getters.servicesList.reduce((acc, cur) => acc + cur.price, 0)
    },
    costOfBooking (state, getters) {
      if (state.bookingPreference.booking_plan === 'FLAT_FEE') {
        return state.bookingPreference.booking_amount
      } else if (state.bookingPreference.booking_plan === 'PERCENTAGE') {
        return (getters.servicesCost * (state.bookingPreference.booking_amount / 100))
      } else {
        return 0
      }
    },
    fullTotal (state, getters) {
      return getters.servicesCost
    },
    noonaIdExists (state) {
      return (state.noonaId !== null)
    },
    noonaServicesExist (state) {
      return (state.services.length > 0)
    },
    bookingDateExists (state) {
      return (state.bookingDate !== null)
    },
    bookingFee (state) {
      return state.bookingPreference.booking_amount
    },
    bookingPreference (state) {
      return state.bookingPreference.booking_plan
    },
    businessName (state) {
      return state.businessName
    }
  },
  mutations: {
    SET_NOONA_ID: (state, data) => {
      state.noonaId = data
    },
    SET_SLUG: (state, slug) => {
      state.slug = slug
    },
    SET_SERVICE_IDS: (state, data) => {
      state.serviceIds = data
    },
    SET_SERVICES_LIST: (state, data) => {
      state.services = data
    },
    SET_EMPLOYEE: (state, data) => {
      state.employee = data
    },
    SET_BUSINESS_ID: (state, data) => {
      state.businessId = data
    },
    SET_CUSTOMER: (state, data) => {
      state.customer = data
    },
    SET_DATE: (state, data) => {
      state.bookingDate = data
    },
    SET_BOOKING_PREFERENCE: (state, value) => {
      state.bookingPreference = value
    },
    SET_BUSINESS_NAME: (state, value) => {
      state.businessName = value
    }
  },
  actions: {
    GET_DETAILS: async function (context, payload) {
      // console.log('state: ', payload)
      const data = fetch(`${API_URL}/v1/noona/retrieve/${payload}`)
      return (await data).json()
    },
    DO_BOOKING: async function (context) {
      // console.log('state: ', payload)
      const data = fetch(`${API_URL}/v1/noona/${context.state.noonaId}/services/book`, { method: 'POST', body: JSON.stringify(context.getters.bookingPayload) })
      return (await data).json()
    },
    GET_NOONA_BUSINESSES: async function (context, noonaId) {
      // console.log('state: ', payload)
      const data = fetch(`${API_URL}/v1/noona/${noonaId}/services`)
      return (await data).json()
    },
    GET_NOONA_EMPLOYEES: async function (context, payload) {
      // console.log('state: ', payload)
      const data = fetch(`${API_URL}/v1/noona/${payload.noonaId}/services/employees?serviceID=${payload.serviceIds}`)
      return (await data).json()
    },
    GET_NOONA_TIMESLOTS: async function (context, queryObject) {
      // console.log('state: ', payload)
      // const data = fetch(`${API_URL}/v1/noona/${queryObject.noonaId}/services/timeslots`)
      const seviceQueryString = queryObject.services.map(item => item.selectedServiceIds).flat().join('&service_id=')
      const employeeQueryString = queryObject.employeeId ? `&employee_id=${queryObject.employeeId}` : ''
      const data = fetch(`${API_URL}/v1/noona/${queryObject.noonaId}/services/timeslots?service_id=${seviceQueryString}&business_id=${queryObject.business_id}&start_date=${queryObject.start_date}&end_date=${queryObject.end_date}${employeeQueryString}`)
      return (await data).json()
    }
  },
  modules: {
  }
})
