<template>
  <div
    class="absolute top-0 left-0 right-0 bottom-0 z-50 bg-black bg-opacity-50"
  >
    <div class="w-6/12 p-4 rounded-lg bg-white mx-auto mt-[20%]">
      <div class="flex">
        <div class="flex-1 text-left pr-12">
          <slot></slot>
        </div>
        <a @click="$emit('onClose')" href="#">
          <img class="w-4 h-4 inline-block" src="@/assets/close.png" alt=""/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
