import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './index.css'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import DialogMessageComponent from '@/components/DialogMessageComponent.vue'
import LoadingComponent from '@/components/LoadingComponent.vue'
import mixpanel from 'mixpanel-browser'

mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, { debug: true, track_pageview: false, persistence: 'localStorage' })

const app = createApp(App)
app.use(store)
app.use(router)
app.component('VueDatePicker', VueDatePicker)
app.component('DialogMessageComponent', DialogMessageComponent)
app.component('LoadingComponent', LoadingComponent)
app.mount('#app')
app.config.globalProperties.$trackEvent = (eventName, payload = null) => {
  if (payload) {
    mixpanel.track(eventName, payload)
  } else {
    mixpanel.track(eventName)
  }
}
